import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { AddModalComponent } from "./components/add-modal/add-modal.component";
import { DeleteConfirmationModalComponent } from "./components/delete-confirmation-modal/delete-confirmation-modal.component";
import {
  BsDatepickerModule,
  CollapseModule,
  ModalModule,
  TabsModule,
  TimepickerModule,
  TooltipModule,
} from "ngx-bootstrap";
import { GridModule } from "@progress/kendo-angular-grid";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormControlValidationMsgDirective } from "./directives/validators/validation-message.directive";
import { FormSubmitValidationMsgDirective } from "./directives/validators/submit-validation-msg.directive";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { UploadModule, UploadsModule } from "@progress/kendo-angular-upload";
import { DeleteModalComponent } from "./components/delete-modal/delete-modal.component";
import { ValidationMsgService } from "./directives/validation-message.service";
import { FileUploadComponent } from "./components/file-upload/file-upload.component";
import { InputsModule } from "@progress/kendo-angular-inputs";

@NgModule({
  declarations: [
    AddModalComponent,
    DeleteConfirmationModalComponent,
    DeleteModalComponent,

    FormControlValidationMsgDirective,
    FormSubmitValidationMsgDirective,
    FileUploadComponent,
  ],

  imports: [
    CommonModule,
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    DropDownsModule,
    UploadModule,
    UploadsModule,
    InputsModule,
    TabsModule.forRoot(),

    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    TooltipModule.forRoot(),
  ],

  exports: [
    AddModalComponent,
    DeleteConfirmationModalComponent,
    DeleteModalComponent,

    FormsModule,
    ReactiveFormsModule,
    GridModule,
    TooltipModule,
    BsDatepickerModule,
    DropDownsModule,
    UploadModule,
    UploadsModule,
    TabsModule,
    InputsModule,

    FormControlValidationMsgDirective,
    FormSubmitValidationMsgDirective,
  ],

  providers: [DatePipe, ValidationMsgService],
})
export class SharedModule {}
