import { LoaderInterceptor } from "./core/interceptor/loader.interceptor";
import { AccessTokenInterceptor } from "./core/interceptor/logout.interceptor";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import {
  DatePipe,
  HashLocationStrategy,
  LocationStrategy,
} from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { ToastrModule } from "ngx-toastr";
import { AvatarModule } from "ngx-avatar";

@NgModule({
  declarations: [AppComponent],

  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    AvatarModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 2500,
      positionClass: "toast-top-right",
      preventDuplicates: true,
      closeButton: true,
      progressBar: false,
      maxOpened: 1,
      autoDismiss: true,
      enableHtml: true,
    }),
  ],

  providers: [
    [DatePipe],
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
