import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageService } from "@app/shared/services/local-storage/local-storage.service";
import { environment } from "@env/environment";

@Component({
  selector: "dms-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.scss"],
})
export class NavBarComponent implements OnInit {
  defaultImagePath = environment.defaultImagePath;
  imageUrl = environment.defaultImageUrl;
  userEmail: any;
  userName: any;
  userInfo: any;
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.getUserInfo();
  }

  navigateToDashboard() {
    this.router.navigate(["/dashboard"]);
  }

  getUserInfo() {
    this.userName = this.localStorageService.getLocalStorageItem("username");
    this.userEmail = this.localStorageService.getLocalStorageItem("email");
  }
}
