import { Component, Input, OnInit } from "@angular/core";
import { LayoutService } from "@app/core/layout/services/layout.service";
declare var $: any;
@Component({
  selector: "dms-menu-item",
  templateUrl: "./menu-item.component.html",
  styleUrls: ["./menu-item.component.scss"],
})
export class MenuItemComponent implements OnInit {
  @Input() navItems;
  expanded: boolean;
  term: any;

  constructor(private layoutService: LayoutService) {}

  ngOnInit() {
    $(document).ready(() => {
      const trees: any = $('[data-widget="tree"]');
      if (trees) {
        trees.tree();
      }
    });
  }

  setExpandedTrue(event) {
    if (event.target.value != "") {
      this.expanded = true;
    } else {
      this.expanded = false;
    }
  }

  setTitle(title) {
    this.layoutService.setContentTitle(title);
  }
}
