import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutModule } from "./layout/layout.module";
import { LoaderModule } from "./loader/loader.module";

@NgModule({
  declarations: [],
  imports: [CommonModule, LayoutModule, LoaderModule],
  exports: [LoaderModule, LayoutModule],
})
export class CoreModule {}
