import { Component, OnInit } from "@angular/core";
import { IconConst } from "@app/shared/constants/icon.constant";
import { LocalStorageService } from "@app/shared/services/local-storage/local-storage.service";
import { environment } from "@env/environment.prod";

@Component({
  selector: "dms-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.scss"],
})
export class SideNavComponent implements OnInit {
  defaultImagePath = environment.defaultImagePath;
  imageUrl = environment.defaultImageUrl;
  iconConst = IconConst;
  userInfo: any;
  userName: any;

  navItems = [
    {
      id: 1,
      displayName: "DMS",
      iconName: this.iconConst.DASHBOARD,
      route: "/dashboard",
    },
    {
      id: 2,
      displayName: "Designation",
      iconName: this.iconConst.DASHBOARD,
      route: "/designation",
    },
    {
      id: 3,
      displayName: "Staff",
      iconName: this.iconConst.STAFF,
      route: "/staff",
    },
    {
      id: 4,
      displayName: "Access Role",
      iconName: this.iconConst.ROLE,
      route: "/access-role",
    },
    {
      id: 4,
      displayName: "Company",
      iconName: this.iconConst.COMPANY,
      route: "/company",
    },
    // {
    //   id: 4,
    //   displayName: "RBAC",
    //   iconName: this.iconConst.DASHBOARD,

    //   children: [
    //     {
    //       displayName: "Assignments",
    //       iconName: this.iconConst.ASSIGNMENT,
    //       route: "rbac/assignments",
    //     },
    //     {
    //       displayName: "Roles",
    //       iconName: this.iconConst.DASHBOARD,
    //       route: "rbac/roles",
    //     },

    //     {
    //       displayName: "Routes",
    //       iconName: this.iconConst.DASHBOARD,
    //       route: "rbac/routes",
    //     },
    // {
    //   displayName: "Routes",
    //   iconName: this.iconConst.DASHBOARD,
    //   route: "rbac/routes",
    // },
    // {
    //   displayName: "Item",
    //   iconName: this.iconConst.DASHBOARD,
    //   route: "rbac/item",
    // },
    // ],
    // },
    {
      id: 5,
      displayName: "User",
      iconName: this.iconConst.USER,
      children: [
        {
          displayName: "User List",
          iconName: this.iconConst.DASHBOARD,
          route: "user/user-list",
        },
        {
          displayName: "User Signup",
          iconName: this.iconConst.DASHBOARD,
          route: "user/user-signup",
        },
        {
          displayName: "Reset Password",
          iconName: this.iconConst.DASHBOARD,
          route: "user/reset-password",
        },
      ],
    },
    {
      id: 6,
      displayName: "Mail",
      iconName: this.iconConst.MAIL,
      route: "/mail",
    },
    {
      id: 8,
      displayName: "Shared With me",
      iconName: this.iconConst.SHARE,
      route: "/share",
    },
    {
      id: 7,
      displayName: "File System",
      iconName: this.iconConst.FILE,
      children: [
        {
          displayName: "Category",
          iconName: this.iconConst.DASHBOARD,
          route: "file-system/category",
        },
        {
          displayName: "Files",
          iconName: this.iconConst.DASHBOARD,
          route: "file-system/upload-file",
        },
        // {
        //   displayName: "File List",
        //   iconName: this.iconConst.DASHBOARD,
        //   route: "file-system/file-list",
        // },
        {
          displayName: "Blockchain",
          iconName: this.iconConst.DASHBOARD,
          route: "file-system/blockchain",
        },
        {
          displayName: "File Sharing",
          iconName: this.iconConst.DASHBOARD,
          route: "file-system/file-sharing",
        },
        {
          displayName: "Simple Sign",
          iconName: this.iconConst.DASHBOARD,
          route: "/simple-sign",
        },
      ],
    },
  ];

  constructor(private localStorageService: LocalStorageService) {}

  ngOnInit() {
    this.getUserInfo();
  }

  getUserInfo() {
    this.userName = this.localStorageService.getLocalStorageItem("username");
  }
}
