export const environment = {
  production: true,

  baseIp: "https://dmsback.bentraytech.com/",
  apiPrefix: "api/web/v1/",
  base_api_url: "http://localhost:3000/",

  defaultImageUrl: "./assets/images/",
  defaultImagePath: "./assets/dist/img",
};
