import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { FileRestrictions, SelectEvent } from "@progress/kendo-angular-upload";

@Component({
  selector: "dms-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
})
export class FileUploadComponent implements OnInit {
  formData = new FormData();
  fileUploadForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {}

  buildFileUploadForm() {
    this.fileUploadForm = this.formBuilder.group({
      file_name: "",
    });
  }
  // file upload
  public uploadRestrictions: FileRestrictions = {
    allowedExtensions: [
      ".jpg",
      ".png",
      ".jpeg",
      ".gif",
      ".doc",
      ".docx",
      ".pdf",
      ".mp3",
      ".mp4",
      ".wav",
      ".3gp",
      ".mpeg",
    ],
  };

  clearModal() {
    this.fileUploadForm.reset();
    this.formData.delete("file_name"); //Reset the files uploaded
  }

  public selectEventHandler(e: SelectEvent): void {
    e.files.forEach((file) => {
      if (!file.validationErrors) {
        this.formData.append("file_name", file.rawFile);
        this.fileUploadForm.patchValue({ file_name: file.rawFile });
        this.fileUploadForm.markAsDirty();
      }
    });
  }

  removeEventHandler(event) {}
}
