import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./core/guards/auth/auth.guard";
import { AdminPanelComponent } from "./core/layout/admin-panel/admin-panel.component";

const routes: Routes = [
  {
    path: "login",
    loadChildren: "@modules/auth/login/login.module#LoginModule",
  },
  {
    path: "",
    component: AdminPanelComponent,
    canActivate: [AuthGuard],

    children: [
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
      },
      {
        path: "dashboard",
        loadChildren: "@modules/dashboard/dashboard.module#DashboardModule",
        data: {
          breadcrumb: "Dashboard",
        },
      },
      {
        path: "designation",
        loadChildren:
          "@modules/designation/designation.module#DesignationModule",
        data: {
          breadcrumb: "Designation",
        },
      },
      {
        path: "staff",
        loadChildren: "@modules/staff/staff.module#StaffModule",
        data: {
          breadcrumb: "Staff",
        },
      },
      {
        path: "rbac",
        loadChildren: "@modules/rbac/rbac.module#RbacModule",
        data: {
          breadcrumb: "RBAC",
        },
      },
      {
        path: "profile",
        loadChildren: "@modules/profile/profile.module#ProfileModule",
        data: {
          breadcrumb: "User Profile",
        },
      },
      {
        path: "user",
        loadChildren: "@modules/user/user.module#UserModule",
        data: {
          breadcrumb: "User",
        },
      },
      {
        path: "share",
        loadChildren: "@modules/share/share.module#ShareModule",
        data: {
          breadcrumb: "Shared With Me",
        },
      },
      {
        path: "access-role",
        loadChildren:
          "@modules/access-role/access-role.module#AccessRoleModule",
        data: {
          breadcrumb: "Access Role",
        },
      },
      {
        path: "company",
        loadChildren: "@modules/company/company.module#CompanyModule",
        data: {
          breadcrumb: "Company",
        },
      },
      {
        path: "mail",
        loadChildren: "@modules/mail/mail.module#MailModule",
        data: {
          breadcrumb: "Mail",
        },
      },
      {
        path: "file-system",
        loadChildren:
          "@modules/file-system/file-system.module#FileSystemModule",
        data: {
          breadcrumb: "File System",
        },
      },
      {
        path: "simple-sign",
        loadChildren:
          "@modules/simple-sign-form/simple-sign-form.module#SimpleSignFormModule",
        data: {
          breadcrumb: "Simple Sign",
        },
      },
      {
        path: "**",
        loadChildren: "@modules/error/error.module#ErrorModule",
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // to emit router events when we try to navigate to the same URL
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
