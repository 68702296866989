import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  constructor(private router: Router, private cookieService: CookieService) {}

  public markAsTouched(formGroup: FormGroup): void {
    formGroup.markAsTouched();
    formGroup.updateValueAndValidity();
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      control.updateValueAndValidity({ only: self, emitEvent: true });
      if (control.controls) {
        this.markAsTouched(control);
      }
    });
  }

  logout() {
    this.cookieService.deleteAll();
    this.router.navigate(["/login"]);
  }
}
