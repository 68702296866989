import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { Loader } from "../model/loader";
import { LoaderService } from "../service/loader.service";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnInit {
  show: boolean;
  // color = "primary";
  // mode = "indeterminate";
  // value = 50;
  constructor(private loaderService: LoaderService) {}

  // isLoading: Subject<boolean> = this.loaderService.isLoading;
  ngOnInit() {
    this.loaderService.loaderState.subscribe((state: Loader) => {
      this.show = state.show;
    });
  }
}
